import Header from "../components/Header";
import React from "react"
import BlogList from "../components/BlogList";

const Blog = () => {
  return (
    <>
      <Header />
      <BlogList />
    </>
  );
};

export default Blog;

export const Head = () => <title>Blog | Speed Reader</title>;