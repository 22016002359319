import "../styles/BlogList.css";
import React from "react";
import blog from "../images/blog.png";

const BlogList = () => {
  return (
    <div className="blog-wrapper">
      <div className="blog-heading">
        <h2>Take it from our people people</h2>
      </div>
      <div className="blog-container">
        <div className="blog-item">
          <img src={blog} alt="blog" />
          <h2>
            Tempor amet in voluptate ex quis pariatur consectetur incididunt ut
            reprehenderit ea adipisicing non.
          </h2>
          <p>
            Tempor qui anim sit ex aliqua nisi adipisicing consequat tempor
            irure commodo officia. Enim qui adipisicing sint do consectetur
            laborum id cupidatat. Ea proident ullamco qui consequat excepteur
            eiusmod. Ad ut cillum sit culpa sint officia. Amet dolore fugiat
            anim laboris exercitation labore incididunt nulla eu amet.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogList;
